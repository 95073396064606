.footer_bottom{
    display: flex;
    justify-content: space-between;
    color: var(--text-secondary);
    border-top: 1px solid var(--border-color);
    padding-top: 20px;
    svg{
        width: 24px;
        height: 24px;
        fill: var(--text-primary);
        color: var(--text-primary);
        margin: 0 10px;
    }
}
.rc-footer {
  position: relative;
  clear: both;
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
  line-height: 1.5;
  background-color: #000;
  border-top: 1px solid var(--border-color);
  @media only screen and (max-width: 767.99px) {
    margin-bottom: 65px;
  }
}
.rc-footer a {
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
  transition: all 0.3s;
}
.rc-footer a:hover {
  color: #40a9ff;
}
.rc-footer-container {
  width: 100%;
  margin: auto;
  padding: 20px 0 20px;
}
.rc-footer-columns {
  display: flex;
  justify-content: space-around;
}
.rc-footer-column {
  margin-bottom: 20px;
}
.rc-footer-column h2 {
  position: relative;
  margin: 0 auto;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}
.rc-footer-column-icon {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 102px;
  text-align: center;
  vertical-align: middle;
  margin-inline-end: 0.5em;
  @media only screen and (max-width: 767.99px) {
    width: 40px;
  }
}

.rc-footer-column-icon > span,
.rc-footer-column-icon > svg,
.rc-footer-column-icon img {
  display: block;
  width: 100%;
}
.rc-footer-item {
  margin: 12px 0;
}
.rc-footer-item-icon {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 102px;
  text-align: center;
  vertical-align: middle;
  margin-inline-end: 0.4em;
}
.rc-footer-item-icon > span,
.rc-footer-item-icon > svg,
.rc-footer-item-icon img {
  display: block;
  width: 100%;
}
.rc-footer-item-separator {
  margin: 0 0.3em;
}
.rc-footer-bottom-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 0 16px 0;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
}
.rc-footer-light {
  color: rgba(0, 0, 0, 0.85);
  background-color: transparent;
}
.rc-footer-light h2,
.rc-footer-light a {
  color: rgba(0, 0, 0, 0.85);
}
.rc-footer-light .rc-footer-bottom-container {
  border-top-color: #e8e8e8;
}
.rc-footer-light .rc-footer-item-separator,
.rc-footer-light .rc-footer-item-description {
  color: rgba(0, 0, 0, 0.45);
}
@media only screen and (max-width: 767.99px) {
  .rc-footer {
    text-align: center;
  }
  .rc-footer-container {
    padding: 20px 0;
  }
  .rc-footer-columns {
    display: block;
  }
  .rc-footer-column {
    display: block;
    margin-bottom: 40px;
  }
  .rc-footer-column:last-child {
    margin-bottom: 0;
  }
}
.footer_bottom{
  display: flex;
  justify-content: space-between;
  color: var(--text-secondary);
  border-top: 1px solid var(--border-color);
  padding-top: 20px;
}
.footer_bottom div{
  display: flex;
}
.footer_bottom svg{
  width: 24px;
  height: 24px;
  fill: var(--text-primary);
  color: var(--text-primary);
  margin: 0 10px;
}
