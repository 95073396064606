.App {
    height: 100%;
    font-family: 'Lato';
}

a,
button:focus {
    outline: none !important;
    outline-offset: none !important;
    box-shadow: none !important;
}

.trans {
    transition: all 200ms ease-in-out;
}

html,
body {
    overflow-x: hidden;
}

.loading-gif {
    position: absolute;
    left: 40%;
    top: 70%;
    transform: translateX(-50%) translateY(-50%);
    width: 144px;
    height: 144px;
}
