@font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/Graphik-Regular-Trial.otf');
}

@font-face {
    font-family: 'Graphik-bold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/Graphik-Bold-Trial.otf');
}
