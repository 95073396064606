@import './assets/css/font.css';
body {
    margin: 0;
    font-family: 'Graphik';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'Graphik';
}
:root {
    --background: #fff;
    --background-secondary: #f4f4f5;
    --primary-color: #5542f6;
    --text-primary: #18181b;
    --text-secondary: #71717a;
    --layout-header-background: #fff;
    --rink-card-background: #f4f4f5;
    --border-color: #e4e4e7;
    --badge-background: #f4f4f5;
    --green: #14b8a6;
    --red: #f64242;
    --background-home: #f9fafb;
}
[class='chakra-ui-dark'] {
    --background: #27272a;
    --background-secondary: #27272a;
    --primary-color: #5542f6;
    --text-primary: #fff;
    --text-secondary: #71717a;
    --accent: #71717a;
    --layout-header-background: #27272a;
    --rink-card-background: #3f3f46;
    --border-color: #3f3f46;
    --badge-background: #5542f6;
    --green: #14b8a6;
    --red: #f64242;
}
